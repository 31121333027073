import React, { FC, useEffect, useState } from "react";
import axios from "axios";
import Button from "../../components/Button";
import Member from "../../models/Member";
import {
  nonValidDentalStates,
  nonValidVisionStates,
  noSalesState,
  noSalesStateD2C,
} from "../../utils"; //nonValidVisionStates
import Agent from "../../models/Agent";
import cx from "classnames";

type HomePageProps = {
  member: Member;
  agent: Agent;
  setValidDentalInState: React.Dispatch<React.SetStateAction<boolean>>;
  setValidVisionInState: React.Dispatch<React.SetStateAction<boolean>>;
  setZipCodeState: React.Dispatch<React.SetStateAction<string>>;
  setStep: React.Dispatch<React.SetStateAction<number>>;
};

const HomePage: FC<HomePageProps> = ({
  member,
  agent,
  setValidDentalInState,
  setValidVisionInState,
  setZipCodeState,
  setStep,
}: HomePageProps) => {
  const [zipCode, setZipCode] = useState<string>();
  const [error, setError] = useState("");

  useEffect(() => {
    document
      .querySelector("input")
      ?.addEventListener("keypress", function (evt) {
        if (evt.key !== "Enter") {
          if (evt.which < 48 || evt.which > 57) {
            evt.preventDefault();
          }
        }
      });
  }, []);
  return (
    <div
      className="relative px-0.5 font-inter xs:px-2"
      onSubmit={(e) => {
        e.preventDefault();
        if (zipCode?.length === 5) {
          member.zipCode = zipCode;
          axios
            .get(
              `${process.env.REACT_APP_SMARTYSTREETSCITYSTATE}`,
              {
                params: { zipcode: zipCode },
              }
            )
            .then((response) => {
              if (response?.data[0]?.status === "invalid_zipcode") {
                setError("Invalid zip code");
              } else {
                if (
                  noSalesState.includes(
                    response?.data[0]?.city_states[0]?.state_abbreviation
                  ) || (noSalesStateD2C.includes(
                    response?.data[0]?.city_states[0]?.state_abbreviation
                  ) && agent.id === 660555)
                ) {
                  setError("State is not available");

                } else {
                  if (
                    nonValidDentalStates.includes(
                      response?.data[0]?.city_states[0]?.state_abbreviation
                    )
                  ) {
                    setValidDentalInState(false);
                  }
                  if (
                    nonValidVisionStates.includes(
                      response?.data[0]?.city_states[0]?.state_abbreviation
                    )
                  )
                    setValidVisionInState(false);
                  setZipCodeState(
                    response?.data[0]?.city_states[0]?.state_abbreviation
                  );
                  member.state =
                    response?.data[0]?.city_states[0]?.state_abbreviation;
                  setStep((current) => current + 1);
                }
              }
            });
        } else {
          setError("Zip code must be 5 characters long");
        }
      }}
    >
      {/* UNCOMMENT TO DISABLE NY/CT
    <div
      className="relative font-inter"
      onSubmit={(e) => {
        e.preventDefault();
        if (zipCode?.length === 5) {
          member.zipCode = zipCode;
          axios
            .get(
              `${process.env.REACT_APP_SMARTYSTREETSCITYSTATE}`,
              {
                params: { zipcode: zipCode },
              }
            )
            .then((response) => {
              if (response?.data[0]?.status === "invalid_zipcode") {
                setError("Invalid zip code");
              } else {
                if (
                  nonValidDentalStates.includes(
                    response?.data[0]?.city_states[0]?.state_abbreviation
                  )
                ) {
                  setValidDentalInState(false);
                }
                if (
                  nonValidVisionStates.includes(
                    response?.data[0]?.city_states[0]?.state_abbreviation
                  )
                )
                  setValidVisionInState(false);
                setZipCodeState(
                  response?.data[0]?.city_states[0]?.state_abbreviation
                );
                if (
                  response?.data[0]?.city_states[0]?.state_abbreviation !==
                    "NY" &&
                  response?.data[0]?.city_states[0]?.state_abbreviation !== "CT"
                ) {
                  setStep((current) => current + 1);
                } else {
                  alert(
                    `${response?.data[0]?.city_states[0]?.state_abbreviation} is not a supported state`
                  );
                }
              }
            });
        } else {
          setError("Zip code must be 5 characters long");
        }
      }}
    >
    */}
      <form>
        <div className="flex flex-col items-center justify-center gap-4 py-[33px] lg:container">
          <div className="flex max-w-[831px] flex-col gap-[27px]">
            <div className="sticky top-44 rounded-3xl border-4 border-black bg-white px-[21px] pt-[31px] pb-[26px]">
              <div className="flex w-full flex-col items-center gap-4 md:flex-row">
                <div
                  className={cx(
                    "text-[20px] font-bold leading-[24.2px] text-black "
                  )}
                >
                  Please enter your zip code to begin
                </div>

                <input
                  className={cx(
                    "h-[37px] w-full max-w-[342px]",
                    "md:ml-[22px] md:w-[296px]",
                    "appearance-none rounded-lg",
                    "text-[20px] leading-[24.2px]",
                    "border border-black bg-backgroundSolidGrey",
                    "pl-3 text-black outline-none focus:outline-none"
                  )}
                  type="number"
                  minLength={5}
                  value={zipCode}
                  onChange={(event) => {
                    setZipCode(event?.target?.value.slice(0, 5));
                    setError("");
                  }}
                />

                <div
                  className={cx(
                    "h-[37px] w-full max-w-[342px]",
                    "text-[20px] leading-[24.2px]",
                    "md:ml-[10px] md:w-[112.28px]"
                  )}
                >
                  <Button
                    className="submitZipQuote"
                    text={"Quote"}
                    submit
                    mainPath
                  />
                </div>
              </div>
              {error && (
                <p
                  className={cx(
                    "text-base font-bold text-red",
                    "flex justify-center",
                    "mt-4 md:mt-0"
                  )}
                >
                  {error}
                </p>
              )}
            </div>
            {agent?.id !== 660555 ? (
              <div
                className={cx(
                  "flex flex-col overflow-y-auto",
                  "rounded-3xl border-4 border-solid border-black bg-white",
                  "px-[21px] pt-[23px] pb-[14px] text-black"
                )}
              >
                <div className="max-w-[708px]">
                  <div className="mb-5 text-[24px] font-semibold leading-[29.05px]">
                    NCD: Insurance, Reimagined{" "}
                  </div>
                  <p className="my-4 text-[20px] leading-[24.2px]">
                    When it comes to quality care, it isn’t just about your
                    eyes, or your teeth.
                    <br />
                    It’s about you — all of you.
                  </p>{" "}
                  <p className="my-4 max-w-[640px] text-[20px] leading-[24.2px]">
                    At NCD, we’re here to empower all of our members to make
                    their best healthcare decisions, to reach their goals, and
                    to enjoy a well-lived, healthy, and smile-worthy life. We
                    want to change lives and Spread the Smile, and that’s what
                    drives us to do better, and offer incredible, industry-first
                    products.{" "}
                  </p>
                  <p className="mt-4 text-[20px] leading-[24.2px]">
                    Why pick and choose, when you could have so much more than
                    insurance?
                  </p>
                  <p className="mt-4 text-[20px] leading-[24.2px]">
                    Plans available for <a href="https://nationalwellnessandfitness.com/?utm_source=Agent+Assisted+NCD+MetLife+Enrollment+Platform&utm_medium=direct&utm_id=NWFA%2C+Direct+Traffic" className="text-blue" target="_blank" rel="noopener noreferrer">National Wellness and Fitness</a> members.
                  </p>
                  {/* 
                <p className="mt-4 text-[20px] leading-[24.2px]">
                  Ready to move forward? Enter your zip code above to select the
                  plan that's best for you.
                </p>
                */}
                </div>
              </div>
            ) : (
              <div
                className={cx(
                  "flex flex-col overflow-y-auto",
                  "rounded-3xl border-4 border-solid border-black bg-white",
                  "py-6 px-6 pb-3 text-black"
                )}
              >
                <div className="self-start text-[24px] font-semibold leading-[29.05px]">
                  NCD: Exceptional Products.
                </div>
                <div className="mb-3 self-start text-[24px] font-semibold leading-[29.05px]">
                  Outstanding Service.
                </div>
                <p className="my-3 text-[20px] leading-[24.2px] md:max-w-[667px]">
                  Your health should be your top priority. It is for us.
                </p>
                <p className="my-3 text-[20px] leading-[24.2px] md:max-w-[667px]">
                  By choosing NCD, you're getting more than just great vision or
                  dental coverage. You're getting care that goes beyond. Care
                  that makes a difference to your whole body, care that makes
                  your life a little easier.
                </p>
                <p className="my-3 text-[20px] leading-[24.2px] md:max-w-[667px]">
                  So check us out. We're doing something different, because we
                  believe that insurance should do more.
                </p>
                <div className="my-3 self-start text-[20px] font-semibold leading-[24.2px]">
                  Our Family of Exceptional Products
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2">
                  <div className="md:max-w-[319px]">
                    <div className="my-3 self-start text-[20px] font-semibold leading-[24.2px]">
                      NCD by MetLife
                    </div>
                    <ul className="my-3 list-disc pl-[25px] text-[20px] leading-[24.2px]">
                      <li>
                        Annual max benefits ranging from $750 to $10,000!
                      </li>
                      <li>100% coverage for preventive care*</li>
                      <li>
                        Additional Coverage for fillings, crowns, surgery, and
                        much more!
                      </li>
                    </ul>
                    <a
                      href="#"
                      className="my-3 pl-[25px] text-[20px] font-bold leading-[24.2px] text-[#003764]"
                    >
                      Apply Now
                    </a>
                  </div>
                  <div className="md:max-w-[319px]">
                    <div className="my-3 self-start text-[20px] font-semibold leading-[24.2px]">
                      VSP Preferred Vision by NCD
                    </div>
                    <ul className="my-3 list-disc pl-[25px] text-[20px]  leading-[24.2px]">
                      <li>
                        $20 exam/$25 materials per covered person per office
                        visit
                      </li>
                      <li>
                        Great coverage on single, lined bifocal, lined trifocal,
                        progressive lenses, and contacts.
                      </li>
                      <li>
                        Average 25%-30% savings on other lens enhancements
                      </li>
                    </ul>
                    <a
                      href="#"
                      className="my-3 pl-[25px] text-[20px] font-bold leading-[24.2px] text-[#003764]"
                    >
                      Apply Now
                    </a>
                  </div>
                </div>
                <p className="mt-5 text-[20px] leading-[24.2px]">
                  Ready to move forward? Enter your zip code above to select the
                  plan that's best for you.
                </p>
                <div className="mt-10 mb-3 self-start text-[20px] font-semibold leading-[24.2px]">
                  An Outstanding Service Team
                </div>
                <p className="mt-3 text-[20px] leading-[24.2px] md:max-w-[667px]">
                  When it comes to member care, we've got a team you can put
                  your trust in. Our US-based Member Care Team is dedicated to
                  treating each and every one of our members with the care and
                  consideration they deserve.
                </p>
                <p className="text-[20px] leading-[24.2px]">
                  Member Care Team Highlights
                </p>
                <ul className="mb-3 list-disc pl-[25px] text-[20px]  leading-[24.2px]">
                  <li>Phone calls answered in 30 seconds or less</li>
                  <li>Emailed returned in 1 hour or less</li>
                </ul>
                <p className="mt-4 text-[20px] leading-[24.2px]">
                  Plans available for <a href="https://nationalwellnessandfitness.com/?utm_source=D2C+NCD+MetLife+Enrollment+Platform&utm_medium=direct&utm_id=NWFA%2C+Direct+Traffic" className="text-blue" target="_blank" rel="noopener noreferrer">National Wellness and Fitness</a> members.
                </p>
                <p className="italic mt-4 text-[14px] leading-[24.2px]">
                  <span>
                    *
                  </span>
                  Some in-network preventive services are subject to copayments on NCD Smile by MetLife plans. Out-of-network, preventive services are subject to deductibles/cost-sharing on NCD Smile by MetLife plans.
                </p>
              </div>
            )}
            <div
              className={cx(
                "flex flex-col justify-center sm:justify-between",
                "overflow-y-auto rounded-3xl border-4 border-solid border-black bg-white",
                "p-[21px] text-black sm:flex-row"
              )}
            >
              <div>
                <div className="text-[24px] font-semibold leading-[29.05px] text-black">
                  We Are Spreading The Smile!
                </div>
                <p className="mt-[20px] text-[20px] leading-[24.2px]">
                  Sounds too good to be true?
                  <br />
                  See what our other customers are saying!
                </p>
              </div>
              <div className="elfsight-app-a4711b9a-6add-4fc7-9f4d-c1092a299c74"></div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default HomePage;
